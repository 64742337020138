<template>
    <b-table
            ref="refUserListTable"
            :items="items"
            :small="true"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="اطلاعاتی یافت نشد"
            class="text-nowrap"
            style="min-height : 235px"
    >
        <template #cell(label)="{item}">
            <b-media vertical-align="center">
                <b-link
                        class="font-weight-bold d-block text-nowrap"
                >
                    {{ item.label }}
                </b-link>
                <small class="text-muted">{{ item.name }}</small>
            </b-media>
        </template>
        <template #cell(fee)="{item}">
            <table-field-base :item="item"
                              key-name="fee"
                              tooltip="اصلاح مقدار"
                              :address="'/ranks/' + item.id"
            />
        </template>
        <template #cell(criterion)="{item}">
            <table-field-base :item="item"
                              key-name="criterion"
                              tooltip="اصلاح مقدار"
                              :address="'/ranks/' + item.id"
            />
        </template>

    </b-table>
</template>

<script>
import {
    // BAvatar,
    BLink,
    BMedia,
    BTable
} from "bootstrap-vue";
import TableFieldBase from "@/Components/TableFieldBase";

export default {
    name: "AccountLevelTable",
    components: {
        TableFieldBase,
        BTable,
        BMedia,
        // BAvatar,
        BLink,
    },
    data() {
        return {
            dir: false,
            loading: false,
            columns: [
                {
                    label: 'نام سطح',
                    key: 'label',
                    sortable: false,
                },
                {
                    label: 'کارمزد',
                    key: 'fee',
                    sortable: false,
                },
                {
                    label: 'مقدار تا سطح بعد',
                    key: 'criterion',
                    sortable: false,
                },
            ],
            edit: false,
            type: false,
            items: []
        }
    },
    methods: {
        async getData() {
            this.state.loading = true

            const res = await this.$axios.get('/ranks')

            this.items = res.data.data
        },
    },
    async created() {
        await this.getData()
    }
}
</script>

<style scoped>

</style>
