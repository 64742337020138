<template>
    <b-card no-body class="p-1">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <b-row>
                <b-col cols="12">
                    <AccountLevelTable />
                </b-col>
            </b-row>
        </b-overlay>
    </b-card>
</template>

<script>
import {
    BCard,
    BOverlay,
    BRow,
    BCol,
} from 'bootstrap-vue'
import AccountLevelTable from "@/views/AccountLevel/AccountLevelTable";

export default {
    name: "AccountLevel",
    components: {
        BCard,
        BOverlay,
        BRow,
        BCol,
        AccountLevelTable
    },
    data: () => ({
        dir: false,
        loading: false,
        coins: [],
        data: {},
        edit: false,
        type: false
    }),
}
</script>

<style scoped>

</style>
